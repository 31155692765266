import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {MatButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {
  MatStep, MatStepContent,
  MatStepLabel,
  MatStepper,
  MatStepperNext,
  MatStepperPrevious,
  StepperOrientation
} from "@angular/material/stepper";
import {AsyncPipe, NgForOf, NgIf, NgSwitchCase} from "@angular/common";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {BreakpointObserver} from "@angular/cdk/layout";
import {forkJoin, map, Observable, of, startWith, switchMap} from "rxjs";
import {MatOption, MatSelect} from "@angular/material/select";
import {DmsWorkflowTemplate} from "../../../model/dmsWorkflowTemplate";
import {DmsWorkflowStartDetailsResponseSchema} from "../../../model/dmsWorkflowStartDetailsResponseSchema";
import {DmsWorkflowTemplates} from "../../../services/api/dms-workflow-templates";
import {TypeAllowedLinkedItemTypesEnum} from "../../../model/typeAllowedLinkedItemTypesEnum";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {DmsSzervUserItemWithNames} from "../../../model/dmsSzervUserItemWithNames";
import {DmsUsersService} from "../../../services/api/dms-users.service";
import {
  MetaDataSzervuserAcComponent
} from "../../mvp/shared/metadata/meta-data-szervuser-ac/meta-data-szervuser-ac.component";
import {MetaDataSzervuserComponent} from "../../mvp/shared/metadata/meta-data-szervuser/meta-data-szervuser.component";
import {MetaDataSzotarAcComponent} from "../../mvp/shared/metadata/meta-data-szotar-ac/meta-data-szotar-ac.component";
import {MetaDataSzotarComponent} from "../../mvp/shared/metadata/meta-data-szotar/meta-data-szotar.component";
import {MetaDataWorkflowComponent} from "../../mvp/shared/metadata/meta-data-workflow/meta-data-workflow.component";
import {SharedModule} from "../../../modules/shared/shared.module";
import {DmsMetaService} from "../../../services/api/dms-meta.service";
import {tap} from "rxjs/operators";
import {DmsAttachmentTypeItem} from "../../../model/dmsAttachmentTypeItem";
import {DmsAttachmentSchema} from "../../../model/dmsAttachmentSchema";
import {DmsStartWorkflowRequestSchema} from "../../../model/dmsStartWorkflowRequestSchema";
import {DomSanitizer} from "@angular/platform-browser";
import {DmsMetadataSchema} from "../../../model/dmsMetadataSchema";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DmsWorkflowProcessService} from "../../../services/api/dms-workflow-process.service";
import {DmsAttachmentPayloadSchema} from "../../../model/dmsAttachmentPayloadSchema";
import {MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle} from "@angular/material/expansion";
import {QuillEditorComponent} from "ngx-quill";
import {DmsWorkflowDigraph} from "../../../services/api/dms-workflow-digraph";
import { instance } from "@viz-js/viz";

@Component({
  selector: 'app-dialog-new-wf-task-box',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    MatIconButton,
    TranslateModule,
    MatStepper,
    AsyncPipe,
    MatStep,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatStepperNext,
    MatStepperPrevious,
    MatStepLabel,
    MatLabel,
    MatSelect,
    MatOption,
    NgForOf,
    NgIf,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatHint,
    FormsModule,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MetaDataSzervuserAcComponent,
    MetaDataSzervuserComponent,
    MetaDataSzotarAcComponent,
    MetaDataSzotarComponent,
    MetaDataWorkflowComponent,
    NgSwitchCase,
    SharedModule,
    MatStepContent,
    MatMiniFabButton,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    QuillEditorComponent
  ],
  templateUrl: './dialog-new-wf-task-box.component.html',
  styleUrl: './dialog-new-wf-task-box.component.scss'
})
export class DialogNewWfTaskBoxComponent implements OnInit{

  templates: DmsWorkflowTemplate[] = [];
  templateDescription: string = '';

  showMetadata = true;

  actualTemplate!: DmsWorkflowStartDetailsResponseSchema;
  newWorkflowdata!: DmsStartWorkflowRequestSchema;

  minDate = new Date();

  firstFormGroup = this._formBuilder.group({
    templateCtrl: [{value: '', disabled: false}, Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    subjectCtrl: ['', Validators.required],
    deadlineCtrl: ['', Validators.required],
    relatedItemCtrl: [{ value: '', disabled: true }, Validators.pattern('')],
    relatedItemTypeCtrl: [{ value: '', disabled: false }],
    descriptionCtrl: ['']
  });
  thirdFormGroup = this._formBuilder.group({});
  fourthFormGroup = this._formBuilder.group({});
  stepperOrientation: Observable<StepperOrientation>;

  myControl = new FormControl<string | DmsSzervUserItemWithNames>('');
  options: DmsSzervUserItemWithNames[] = [];
  filteredOptions!: Observable<DmsSzervUserItemWithNames[]>;

  notFound: boolean = false;

  sendUser: DmsSzervUserItemWithNames = {userName: '', userId: 0, orgName: '', orgId: 0};

  requestActualTemplate!: DmsWorkflowStartDetailsResponseSchema;
  requestDescriptorIdSet = new Set<string>();
  requestTypeIdSet = new Set<string>();

  szotarOptionList!: any;
  mfTorzsOptionList!: any;
  uploadFileList: DmsAttachmentSchema[] = [];
  attachmentTypes: DmsAttachmentTypeItem[] = [];
  readonly MAX_FILE_SIZE = 104857600; // 100MB in bytes
  isDragOver = false;

  isUploading = false;
  isTemplateLoading = false;

  selectedItemType: string | null = null;
  relatedItemPlaceholder = '';
  isRelatedItemSearchActive = false;

  isEditor = false;

  constructor(public dialogRef: MatDialogRef<DialogNewWfTaskBoxComponent>,
              private _formBuilder: FormBuilder,
              breakpointObserver: BreakpointObserver,
              private dmsWorkflowTemplates: DmsWorkflowTemplates,
              private dmsUserService: DmsUsersService,
              private dmsMetaService: DmsMetaService,
              public sanitizer: DomSanitizer,
              private _snackBar: MatSnackBar,
              private translate: TranslateService,
              private dmsWorkflowProcessService: DmsWorkflowProcessService,
              private dmsWorkflowDigraph: DmsWorkflowDigraph) {
    dialogRef.disableClose = true;
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit() {
    this.dmsWorkflowTemplates.readDmsWorkflowAvailableTemplates$().subscribe(response => {
      this.templates = response.result;
    })
    this.dmsMetaService.readDmsAttachmentType$().subscribe(response => {
      if (response.result) {
        this.attachmentTypes = response.result;
      }
    })
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  isAllFormValid(): boolean {
    return this.firstFormGroup.valid &&
      this.secondFormGroup.valid &&
      this.thirdFormGroup.valid &&
      this.fourthFormGroup.valid &&
      this.isMandatoryDataFilled() &&
      !this.isRelatedItemSearchActive &&
      !this.isUploading;
  }

  onTemplateSelected(option: DmsWorkflowTemplate) {

    this.relatedItemPlaceholder = '';
    this.isEditor = false;
    this.selectedItemType = null;
    this.isRelatedItemSearchActive = false;

    const value: number = option.id;
    this.templateDescription = option.description;

    //this.firstFormGroup.controls.templateCtrl.setErrors({ 'required': true });

    this.secondFormGroup.reset();
    this.secondFormGroup.controls.relatedItemCtrl.disable();
    this.myControl.reset();
    this.removeAllFiles();

    this.requestDescriptorIdSet.clear();
    this.requestTypeIdSet.clear();
    this.mfTorzsOptionList = null;
    this.szotarOptionList = null;

    this.isTemplateLoading = true;
    this.dmsWorkflowTemplates.readDmsWorkflowStartDetailsByWorkflowTemplateId$(value)
      .pipe(
        tap(data => {
          this.preProcessData(data.result);
        }),
        switchMap((data) => {
          const requests = [];

          const wfData$ = of(data);
          requests.push(wfData$);

          if (this.requestDescriptorIdSet.size > 0) {
            const mfMasterOptionList$ = this.dmsMetaService
              .readDmsMfTorzsListByLeiroId$(Array.from(this.requestDescriptorIdSet).join(','))
              .pipe(map((result) => result.result));
            requests.push(mfMasterOptionList$);
          } else {
            requests.push(of(null));
          }

          if (this.requestTypeIdSet.size > 0) {
            const szotarOptionList$ = this.dmsMetaService
              .readDmsSzotarListByTypeId$(Array.from(this.requestTypeIdSet).join(','))
              .pipe(map((result) => result.result));
            requests.push(szotarOptionList$);
          } else {
            requests.push(of(null));
          }

          return forkJoin(requests);
        }),
        map(([wfData, mfMasterOptionList, szotarOptionList]) => {
          this.actualTemplate = wfData.result;
          this.showMetadata = this.actualTemplate.metadata && this.actualTemplate.metadata.length > 0;
          this.mfTorzsOptionList = mfMasterOptionList;
          this.szotarOptionList = szotarOptionList;

          this.secondFormGroup.controls.deadlineCtrl.setValue(this.actualTemplate.deadline);
          this.secondFormGroup.controls.deadlineCtrl.updateValueAndValidity();

          this.newWorkflowdata = {
            templateId: value.toString(),
            title: '',
            deadline: this.actualTemplate.deadline,
            responsibleUser: {
              userId: 0,
              orgId: 0
            }
          }

          //this.secondFormGroup.controls.relatedItemCtrl.enable();

          this.isTemplateLoading = false;
          this.firstFormGroup.controls.templateCtrl.setErrors(null);
        })
      )
      .subscribe({
        error: (err) => {
          this.firstFormGroup.controls.templateCtrl.setErrors({ 'error': true });
          console.error('Error fetching template details:', err);
        }
      });
  }

  preProcessData(incomingData: DmsWorkflowStartDetailsResponseSchema){

    const valueChangeTypes = ['Alszam', 'Foszam'];
    const masterTypes = ['MFTorzs', 'MFTorzsAC'];
    const dictTypes = ['Szotar', 'SzotarAC'];

    this.requestDescriptorIdSet.clear();
    this.requestTypeIdSet.clear();

    incomingData.metadata?.forEach(metaData => {
      if (valueChangeTypes.includes(metaData.type)) {
        metaData.value = metaData.humanvalue;
      }
      if (masterTypes.includes(metaData.type) && metaData.visibility !== 'MT_O') {
        this.requestDescriptorIdSet.add(metaData.params.toString());
      }
      if (dictTypes.includes(metaData.type) && metaData.visibility !== 'MT_O') {
        this.requestTypeIdSet.add(metaData.params.toString());
      }
    });

    this.requestActualTemplate = incomingData;
  }

  enableRelatedItem(value: TypeAllowedLinkedItemTypesEnum){

    if(this.newWorkflowdata.linkedItem) {
      delete this.newWorkflowdata.linkedItem
    }

    if(value){
      let newPattern;

      switch (value) {
        case 'alszam':
          newPattern = /^(?:([\p{L}\p{N} \w.!*_\/-]+)\/)?([0-9]+)-([0-9]+)\/((?:19|20)[0-9]{2})(?:\/([\p{L}\p{N} \w.!*_\/-]+))?$/u;
          this.relatedItemPlaceholder = 'Ex. 1-1/2025';
          break;
        case 'foszam':
          newPattern = /^(?:([\p{L}\p{N} \w.!*_\/-]+)\/)?([0-9]+)\/((?:19|20)[0-9]{2})(?:\/([\p{L}\p{N} \w.!*_\/-]+))?$/u;
          this.relatedItemPlaceholder = 'Ex. 1/2025';
          break;
        default:
          newPattern = '';
          this.relatedItemPlaceholder = '';
          break;
      }

      this.secondFormGroup.controls.relatedItemCtrl.setValidators([
        Validators.pattern(newPattern),
        Validators.required
      ]);


      this.selectedItemType = value;

      this.secondFormGroup.controls.relatedItemCtrl.enable();

    } else {
      this.relatedItemPlaceholder = '';
      this.secondFormGroup.controls.relatedItemCtrl.setValidators([]);
      this.secondFormGroup.controls.relatedItemCtrl.disable();
    }

    this.secondFormGroup.controls.relatedItemCtrl.setValue('');
    this.secondFormGroup.controls.relatedItemCtrl.updateValueAndValidity();

  }

  setDeadline(date: Date){
    if(date) {
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      this.actualTemplate.deadline = `${year}-${month}-${day}`;
    } else {
      this.actualTemplate.deadline = '';
    }
    this.newWorkflowdata.deadline = this.actualTemplate.deadline;
  }

  onSearchClicked(value: string){
    this.dmsUserService.readDmsAcUser$(value).subscribe(response => {
      this.options = response.result;
      this.filtering();
    });
  }

  private filtering(){
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.userName;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
    this.notFound = this.options.length === 0;
  }

  displayFn(user: DmsSzervUserItemWithNames): string {
    return user && user.userName ? user.userName : '';
  }

  private _filter(name: string): DmsSzervUserItemWithNames[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.userName.toLowerCase().includes(filterValue));
  }

  setResponsibleUser(user: DmsSzervUserItemWithNames){
    this.sendUser = user;
    this.newWorkflowdata.responsibleUser = {
      userId: this.sendUser.userId,
      orgId: this.sendUser.orgId
    }
  }

  removeFile(index: number) {
    this.uploadFileList.splice(index, 1);
  }
  removeAllFiles() {
    this.uploadFileList = [];
  }

  formatFileSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size >= gb) {
      return (size / gb).toFixed(2) + ' GB';
    } else if (size >= mb) {
      return (size / mb).toFixed(2) + ' MB';
    } else if (size >= kb) {
      return (size / kb).toFixed(2) + ' KB';
    } else {
      return size + ' Bytes';
    }
  }

  uploadingFiles(event: Event){ //nem megfelelő input mezőt néz
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFilesToUploadList(input.files);
      input.value = '';
    }
  }

  private addFilesToUploadList(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file) {
        this.uploadFileList.push({
          content: file,
          attachmentTypeCode: null,
          fileName: file.name
        });
      }
    }
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
    const files = event.dataTransfer?.files;
    if (files) {
      this.addFilesToUploadList(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    if (!this.isMouseInsideDropzone(event)) {
      this.isDragOver = false;
    }
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event: DragEvent) {
    this.isDragOver = false;
  }

  private isMouseInsideDropzone(event: DragEvent): boolean {
    const dropzone = document.querySelector('.dropzone') as HTMLElement;
    if (!dropzone) return false;
    const { top, left, bottom, right } = dropzone.getBoundingClientRect();
    const { clientX, clientY } = event;
    return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
  }

  // createWorkflow(){
  //
  //   if(this.checkAllData()){
  //
  //     if (this.uploadFileList && this.uploadFileList.length > 0) {
  //       this.newWorkflowdata.files = this.uploadFileList;
  //     }
  //
  //     let send_metadata: {code: string, value: string | number | null}[];
  //     send_metadata = this.actualTemplate.metadata.map((item: DmsMetadataSchema) =>
  //       ({
  //         code: item.code,
  //         value: item.type === 'Check' ? (item.value ? '1' : '0') : item.value
  //       })
  //     );
  //     send_metadata.forEach(item => {
  //       if(item.value == '') item.value=null;
  //     })
  //
  //     const sendContent: DmsStartWorkflowRequestSchema = {
  //       templateId: this.newWorkflowdata.templateId,
  //       title: this.newWorkflowdata.title,
  //       deadline: this.newWorkflowdata.deadline,
  //       description: this.newWorkflowdata.description || '',
  //       responsibleUser: this.newWorkflowdata.responsibleUser,
  //       linkedItem: this.newWorkflowdata.linkedItem,
  //       metadata: send_metadata.reduce<{ [key: string]: any }>(
  //         (acc, item) => {
  //           acc[item.code] = item.value;
  //           return acc;
  //         }, {}),
  //       files: this.newWorkflowdata.files || []
  //     };
  //
  //     const sendContentJson = JSON.stringify(sendContent, null, 2);
  //     console.log('Workflow Request Data:', sendContentJson);
  //   }
  // }

  async createWorkflow() {

    this.isUploading = true;

    const isDataValid = await this.checkAllData();

    if (isDataValid) {
      const filePromises: Promise<DmsAttachmentPayloadSchema>[] =
        this.uploadFileList.length > 0
          ? this.uploadFileList.map(fileItem =>
            this.convertFileToBase64(fileItem.content).then(base64Content => ({
              content: base64Content,
              attachmentTypeCode: fileItem.attachmentTypeCode || null,
              fileName: fileItem.fileName
            }))
          )
          : [];

      Promise.all(filePromises).then((encodedFiles: DmsAttachmentPayloadSchema[]) => {
        const send_metadata = this.actualTemplate.metadata.reduce((acc, item) => {
          acc[item.code] = item.type === 'Check' ? (item.value ? '1' : '0') : item.value;
          return acc;
        }, {} as { [key: string]: string | number | null });

        const sendContent = {
          templateId: this.newWorkflowdata.templateId,
          title: this.newWorkflowdata.title,
          deadline: this.newWorkflowdata.deadline,
          description: this.secondFormGroup.controls.descriptionCtrl.value ? this.secondFormGroup.controls.descriptionCtrl.value : '',
          responsibleUser: this.newWorkflowdata.responsibleUser,
          linkedItem: this.newWorkflowdata.linkedItem,
          metadata: send_metadata,
          files: encodedFiles
        };

        const sendContentJson = JSON.stringify(sendContent, null, 2);
        this.dmsWorkflowProcessService.createDmsWorkflowStart$(sendContentJson).subscribe({
          next: (response) => {
            this.dialogRef.close(true);
          },
          error: (err) => {
            this.isUploading = false;
          },
          complete: () => {
            this.isUploading = false;
          }
        });
      }).catch((error) => {
          // Re-enable button if there’s an error in file processing
          console.error("File processing error:", error);
          this.isUploading = false;
      });
    } else {
      this.isUploading = false;
    }
  }

  onSubjectChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.newWorkflowdata.title = inputElement.value;
  }
  public trustHtml(textToHtml: string | undefined) {
    if (textToHtml) {
      let htmlText = this.htmlListFormatting(textToHtml);
      return this.sanitizer.bypassSecurityTrustHtml(htmlText);
    } else {
      return "";
    }
  }

  private htmlListFormatting(htmlString: string){
    let result = htmlString.replaceAll('<ol', '<ul');
    result = result.replaceAll('</ol', '</ul');
    return result;
  }

  private isMandatoryDataFilled(): boolean {
    if(!(this.newWorkflowdata.responsibleUser.userId>0) || !(this.newWorkflowdata.responsibleUser.orgId>0)){
      return false;
    }
    if (this.actualTemplate.metadata && this.actualTemplate.metadata.length > 0) {
      const hasProblem = this.actualTemplate.metadata.some((item: DmsMetadataSchema) => {
        if (item.visibility === 'MT_K' && (item.value === null || item.value === undefined || item.value === '')) {
          return true;
        }

        if (item.visibility === 'MT_K' || (item.visibility === 'MT_M')) {
          if (item.type === 'Alszam' && item.value && item.value.length>0) {
            const alszamPattern = /^([^\/]+\/)?[1-9]\d*-\d+\/\d{4}(\/[^\/]+)?$/;
            if (!alszamPattern.test(item.value)) {
              return true;
            }
          } else if (item.type === 'Foszam' && item.value && item.value.length>0) {
            const foszamPattern = /^([^\/]+\/)?[1-9]\d*\/\d{4}(\/[^\/]+)?$/;
            if (!foszamPattern.test(item.value)) {
              return true;
            }
          }
        }

        return false;
      });

      return !hasProblem;
    }

    return true;
  }

  async checkAllData(): Promise<boolean> {
    const isFilesValid = this.checkUploadFiles();
    //const isRelatedItemValid = await this.checkRelatedItem();
    const isMetadataValid = await this.checkMetadata();
    //return isFilesValid && isRelatedItemValid && isMetadataValid;
    return isFilesValid && isMetadataValid;
  }

  checkUploadFiles(){
    if(this.uploadFileList && this.uploadFileList.length > 0) {
      const tooLargeFiles = this.uploadFileList.filter(
        file => file.content.size > this.MAX_FILE_SIZE
      );
      if (tooLargeFiles.length > 0) {
        const message = this.translate.instant('REMOVE_LARGE_FILES');
        const action = this.translate.instant('OK');
        this._snackBar.open(message, action, {
          duration: 5000,
          panelClass: ['snackbar-warning']
        });
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkRelatedItem(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.newWorkflowdata.linkedItem) {
        const tempType = this.newWorkflowdata.linkedItem.linkedItemType;
        const tempId = this.newWorkflowdata.linkedItem.id.toString();

        this.dmsMetaService.readDmsLinkedItemById$(tempId, tempType).subscribe(response => {
          const result = response.result as { id: number; identifier: string };

          if (result && 'id' in result) {
            this.newWorkflowdata.linkedItem = {
              linkedItemType: tempType,
              id: result.id
            };
            resolve(true);
          } else {
            const message = this.translate.instant('LINKED_ITEM_NOT_EXIST');
            const action = this.translate.instant('OK');
            this._snackBar.open(message, action, {
              duration: 5000,
              panelClass: ['snackbar-warning']
            });
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  async checkMetadata(): Promise<boolean> {
    if (this.actualTemplate.metadata && this.actualTemplate.metadata.length > 0) {
      for (const item of this.actualTemplate.metadata) {
        if ((item.visibility === 'MT_K' || item.visibility === 'MT_M') &&
          (item.type === 'Alszam' || item.type === 'Foszam')) {

          const tempType = item.type === 'Alszam' ? 'alszam' : 'foszam';
          const tempIdentifier = item.value;

          if (tempIdentifier && tempIdentifier.trim()) {
            try {
              const response = await this.dmsMetaService.readDmsLinkedItemById$(tempIdentifier, tempType).toPromise();
              const result = response!.result as { id: string; identifier: string };

              if (!result || !('id' in result)) {
                const messageKey = item.type === 'Alszam' ? 'DOCUMENT_IN_METADATA_NOT_EXIST' : 'FOLDER_IN_METADATA_NOT_EXIST';
                const message = this.translate.instant(messageKey);
                const action = this.translate.instant('OK');
                this._snackBar.open(message, action, {
                  duration: 5000,
                  panelClass: ['snackbar-warning']
                });
                return false;
              }
            } catch (error) {
              console.error(`Error checking ${tempType}:`, error);
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  openDiagram(){
    this.dmsWorkflowDigraph.readDmsWorkflowDigraph$(this.actualTemplate.id).subscribe( response => {
      if (response.result) {
        const digraph = response.result.digraph;

        instance().then(viz => {
          const svgContent = viz.renderString(digraph, {format: "svg"});
          this.openSvgInNewWindow(svgContent);
        }).catch((error: any) => {
          console.error('Error initializing Viz.js:', error);
        });
      }
    });
  }

  openSvgInNewWindow(svgContent: string): void {

    const title = 'Diagram';

    const width = 800;
    const height = 600;
    const left = (screen.width / 2) - (width / 2);
    const top = (screen.height / 2) - (height / 2);

    const newWindow = window.open(
      '',
      '_blank',
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
    );

    if (newWindow) {

      newWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${title}</title>
        <style>
          body {
            margin: 0;
            padding: 0;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background-color: #f0f0f0;
          }
          .svg-container {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: auto;
            width: 100%;
            height: 100%;
            background-color: white;
          }
          svg {
            max-width: 100%;
            max-height: 100%;
            transform-origin: center;
            transition: transform 0.2s ease-in-out;
          }
        </style>
      </head>
      <body>
        <div class="svg-container">
          ${svgContent}
        </div>
        <script>
          const svgElement = document.querySelector('svg');
          let isDragging = false;
          let lastX = 0;
          let lastY = 0;
          let scale = 1;
          let translateX = 0;
          let translateY = 0;

          const svgContainer = document.querySelector('.svg-container');
          svgContainer.style.cursor = 'grab';

          svgContainer.addEventListener('pointerdown', (event) => {
            isDragging = true;
            lastX = event.clientX;
            lastY = event.clientY;
            svgContainer.style.cursor = 'grabbing';
          });

          svgContainer.addEventListener('pointermove', (event) => {
            if (!isDragging) return;
            const deltaX = event.clientX - lastX;
            const deltaY = event.clientY - lastY;

            translateX += deltaX;
            translateY += deltaY;

            applyTransform();
            lastX = event.clientX;
            lastY = event.clientY;
          });

          svgContainer.addEventListener('pointerup', () => {
            isDragging = false;
            svgContainer.style.cursor = 'grab';
          });

          svgContainer.addEventListener('wheel', (event) => {
            event.preventDefault();
            const zoomFactor = 0.1;
            scale += event.deltaY < 0 ? zoomFactor : -zoomFactor;
            scale = Math.max(0.5, Math.min(scale, 10));
            applyTransform();
          });

          function applyTransform() {
            if (svgElement) {
              svgElement.style.transform = \`translate(\${translateX}px, \${translateY}px) scale(\${scale})\`;
            }
          }
        </script>
      </body>
      </html>
    `);

      newWindow.document.close();

    }
  }


  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        resolve(base64String);
      };
      reader.onerror = error => reject(error);
    });
  }

  onBlurRelatedItem(value: string){
    if(this.secondFormGroup.controls.relatedItemCtrl.valid){
      this.isRelatedItemSearchActive = true;
      this.secondFormGroup.controls.relatedItemTypeCtrl.disable();
      this.firstFormGroup.controls.templateCtrl.disable();
      this.searchRelatedItem(value);
    }
  }

  searchRelatedItem(value: string){
    if(this.selectedItemType && value.length>0) {
      this.dmsMetaService.readDmsLinkedItemById$(value, this.selectedItemType).subscribe( {
        next: (response) => {
          const result = response.result as { id: number; identifier: string };
          if (result && 'id' in result && result.id != null && this.selectedItemType) {
            this.newWorkflowdata.linkedItem = {
              linkedItemType: this.selectedItemType,
              id: result.id
            }
          } else {
            this.secondFormGroup.controls.relatedItemCtrl.setErrors({ itemNotExist: true });
          }
          this.isRelatedItemSearchActive = false;
          this.secondFormGroup.controls.relatedItemTypeCtrl.enable();
          this.firstFormGroup.controls.templateCtrl.enable();
        },
        error: (err) => {
          this.secondFormGroup.controls.relatedItemCtrl.setErrors({ itemNotExist: true });
          this.isRelatedItemSearchActive = false;
          this.secondFormGroup.controls.relatedItemTypeCtrl.enable();
          this.firstFormGroup.controls.templateCtrl.enable();
        },
        complete: () => {
          this.isRelatedItemSearchActive = false;
          this.secondFormGroup.controls.relatedItemTypeCtrl.enable();
          this.firstFormGroup.controls.templateCtrl.enable();
        }
      });
    }
  }

  showEditor(){
    this.isEditor = true;
    const text = this.secondFormGroup.controls.descriptionCtrl.value;
    const htmlContent = this.convertTextToHtml(text);
    this.secondFormGroup.controls.descriptionCtrl.setValue(htmlContent);
  }

  convertTextToHtml(text: string | null): string {
    if(text) {
      return text.split('\n').map(line => `<p>${line}</p>`).join('');
    } else return '';
  }

}
