<div style="width: 100%; display: flex; justify-content: space-between">
  <h2 mat-dialog-title style="margin-bottom: 0">{{'CREATE_WORKFLOW' | translate}}</h2>
  <button mat-icon-button (click)="onNoClick()"><mat-icon>close</mat-icon></button>
</div>

<mat-dialog-content style="min-width: 280px; padding-left: 0; padding-right: 0">

  <div>
    <mat-stepper
      class="example-stepper"
      [orientation]="(stepperOrientation | async)!" linear="true">

      <mat-step [stepControl]="firstFormGroup" label="{{'TEMPLATE' | translate}}">
        <form [formGroup]="firstFormGroup">
          <p class="upload-title">{{'CHOOSE_TEMPLATE' | translate}}</p>
          <div class="input-box">
            <span class="input-field-label"><b>{{'TEMPLATE' | translate}}*</b></span>
            <mat-form-field>
              <mat-select formControlName="templateCtrl" required (selectionChange)="onTemplateSelected($event.value)" placeholder="{{'NOT_SELECTED' | translate}}">
                <mat-option *ngFor="let template of templates" [value]="template">{{template.name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormGroup.get('templateCtrl')?.hasError('required')">
                {{'MANDATORY_FIELD' | translate}}
              </mat-error>
              <mat-hint *ngIf="isTemplateLoading">{{'DATA_LOADING' | translate}}</mat-hint>
            </mat-form-field>
          </div>
          <p class="upload-title">{{'DESCRIPTION' | translate}}</p>
          <div *ngIf="!templateDescription">{{'NO_DESCRIPTION' | translate}}</div>
          <div *ngIf="templateDescription" [innerHTML]="trustHtml(templateDescription)"></div>
          <div style="display: flex; flex-direction: row; justify-content: flex-end; gap: 12px">
            <button mat-stroked-button (click)="openDiagram()" [disabled]="!actualTemplate || isTemplateLoading">{{'DIAGRAM' | translate}}</button>
            <button mat-stroked-button matStepperNext [disabled]="!actualTemplate || isTemplateLoading">{{'NEXT' | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondFormGroup" label="{{'INFORMATION' | translate}}" optional="true">
        <ng-template matStepContent>
          <form [formGroup]="secondFormGroup">
            <div class="input-box-unset-width">
              <span class="input-field-label"><b>{{'SUBJECT' | translate}}*</b></span>
              <mat-form-field>
                <input matInput formControlName="subjectCtrl"
                       required (input)="onSubjectChange($event)">
                <mat-error *ngIf="secondFormGroup.get('subjectCtrl')?.hasError('required')">
                  {{'MANDATORY_FIELD' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
            <div style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: space-between">
              <p style="color: #000000DE; font-weight: 500; margin-bottom: 0">{{'DESCRIPTION' | translate}}</p>
              <button mat-icon-button (click)="showEditor()" [disabled]="isEditor"><mat-icon>edit</mat-icon></button>
            </div>
            <mat-form-field style="width: 100%" *ngIf="!isEditor">
              <textarea matInput formControlName="descriptionCtrl"></textarea>
            </mat-form-field>
            <quill-editor class="content-editor" formControlName="descriptionCtrl" style="width: 100%; margin-bottom: 22px" *ngIf="isEditor"></quill-editor>
            <div class="wf-data-div">
              <div class="input-box" *ngIf="actualTemplate">
                <span class="input-field-label"><b>{{'RELATED_ITEM_TYPE' | translate}}</b></span>
                <mat-form-field *ngIf="actualTemplate">
                  <mat-select (selectionChange)="enableRelatedItem($event.value)" placeholder="{{'NOT_SELECTED' | translate}}" formControlName="relatedItemTypeCtrl">
                    <mat-option [value]="null">{{'NOT_SELECTED' | translate}}</mat-option>
                    <mat-option *ngFor="let item of actualTemplate.allowedLinkedItemTypes" [value]="item">{{item}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="input-box" *ngIf="actualTemplate">
                <span class="input-field-label"><b>{{'RELATED_ITEM' | translate}}</b></span>
                <mat-form-field>
                  <input matInput #inputRelatedItem formControlName="relatedItemCtrl" (blur)="onBlurRelatedItem(inputRelatedItem.value)"
                         [placeholder]="relatedItemPlaceholder">
                  <mat-error *ngIf="secondFormGroup.get('relatedItemCtrl')?.hasError('pattern') && !isRelatedItemSearchActive">
                    {{'WRONG_FORMAT' | translate}}
                  </mat-error>
                  <mat-error *ngIf="secondFormGroup.get('relatedItemCtrl')?.hasError('required') && !isRelatedItemSearchActive">
                    {{'MANDATORY_FIELD' | translate}}
                  </mat-error>
                  <mat-error *ngIf="secondFormGroup.get('relatedItemCtrl')?.hasError('itemNotExist') && !isRelatedItemSearchActive">
                    {{'RELATED_ITEM_NOT_EXIST' | translate}}
                  </mat-error>
                  <mat-hint *ngIf="isRelatedItemSearchActive">{{'SEARCHING_FOR_RELATED_ITEM' | translate}}</mat-hint>
                </mat-form-field>
              </div>
              <div class="input-box" *ngIf="actualTemplate">
                <span class="input-field-label"><b>{{'DEADLINE' | translate}}*</b></span>
                <mat-form-field *ngIf="actualTemplate" class="field-width">
                  <input matInput
                         [matDatepicker]="picker"
                         placeholder="YYYY. MM. DD."
                         required
                         [min]="minDate"
                         formControlName="deadlineCtrl"
                         (dateChange)="setDeadline($event.value)">
                  <mat-datepicker-toggle matIconSuffix [for]="picker" class="icon-button"></mat-datepicker-toggle>
                  <mat-datepicker touchUi="true" #picker></mat-datepicker>
                  <mat-error *ngIf="secondFormGroup.get('deadlineCtrl')?.hasError('required')">
                    {{'MANDATORY_FIELD' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="input-box" *ngIf="actualTemplate">
                <span class="input-field-label"><b>{{'RESPONSIBLE_USER' | translate}}*</b></span>
                <mat-form-field class="field-width">
                  <input #input
                         type="text"
                         placeholder="{{'SEARCHING_FOR_PERSON' | translate}}"
                         matInput
                         required
                         [formControl]="myControl"
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" requireSelection="true"
                                    (optionSelected)="setResponsibleUser($event.option.value)">
                    <mat-option *ngIf="notFound" disabled="true">{{'NO_RESULTS' | translate}}</mat-option>
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{option.userName}} ({{option.orgName}})
                    </mat-option>
                  </mat-autocomplete>
                  <button matSuffix mat-icon-button (click)="onSearchClicked(input.value)" class="icon-button">
                    <mat-icon>search</mat-icon>
                  </button>
                  <mat-error *ngIf="myControl.hasError('required')">
                    {{'MANDATORY_FIELD' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end; gap:12px">
              <button mat-stroked-button matStepperPrevious>{{'BACK' | translate}}</button>
              <button mat-stroked-button matStepperNext>{{'NEXT' | translate}}</button>
            </div>
          </form>
        </ng-template>
      </mat-step>

      <mat-step [stepControl]="thirdFormGroup" label="{{'META_DATA' | translate}}" *ngIf="showMetadata">
        <ng-template matStepContent>
<!--          <p class="upload-title">{{'META_DATA' | translate}}</p>-->
          <p *ngIf="!actualTemplate.metadata || actualTemplate.metadata.length === 0" class="upload-title">
            {{'NO_META_DATA' | translate}}
          </p>
          <div class="wf-data-div">
            <ng-container *ngFor="let item of actualTemplate.metadata">
              <ng-container *ngIf="item.visibility==='MT_M'||item.visibility==='MT_K'||item.visibility==='MT_O'">
                <ng-container [ngSwitch]="item.type">
                  <app-meta-data-check *ngSwitchCase="'Check'"
                                       [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                       [visibility]="item.visibility"
                                       [(humanvalue)]="item.humanvalue"
                                       [(value)]="item.value"></app-meta-data-check>

                  <app-meta-data-date *ngSwitchCase="'Date'"
                                      [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                      [visibility]="item.visibility"
                                      [(humanvalue)]="item.humanvalue"
                                      [(value)]="item.value"></app-meta-data-date>

                  <app-meta-data-partner *ngSwitchCase="'Partner'"
                                         [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                         [visibility]="item.visibility"
                                         [(humanvalue)]="item.humanvalue"
                                         [(value)]="item.value"></app-meta-data-partner>

                  <app-meta-data-alszam *ngSwitchCase="'Alszam'"
                                        [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                        [visibility]="item.visibility"
                                        [(humanvalue)]="item.humanvalue"
                                        [(value)]="item.value"></app-meta-data-alszam>

                  <app-meta-data-foszam *ngSwitchCase="'Foszam'"
                                        [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                        [visibility]="item.visibility"
                                        [(humanvalue)]="item.humanvalue"
                                        [(value)]="item.value"></app-meta-data-foszam>

                  <app-meta-data-mftorzs *ngSwitchCase="'MFTorzs'"
                                         [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                         [visibility]="item.visibility"
                                         [mfTorzsOptionList]="mfTorzsOptionList"
                                         [(humanvalue)]="item.humanvalue"
                                         [(value)]="item.value" [params]="item.params"></app-meta-data-mftorzs>

                  <app-meta-data-mftorzs-ac *ngSwitchCase="'MFTorzsAC'"
                                            [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                            [visibility]="item.visibility"
                                            [mfTorzsOptionList]="mfTorzsOptionList"
                                            [(humanvalue)]="item.humanvalue"
                                            [(value)]="item.value" [params]="item.params"></app-meta-data-mftorzs-ac>

                  <app-meta-data-money *ngSwitchCase="'Money'"
                                       [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                       [visibility]="item.visibility"
                                       [(humanvalue)]="item.humanvalue"
                                       [(value)]="item.value"></app-meta-data-money>

                  <app-meta-data-number *ngSwitchCase="'Number'"
                                        [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                        [visibility]="item.visibility"
                                        [(humanvalue)]="item.humanvalue"
                                        [(value)]="item.value"></app-meta-data-number>

                  <app-meta-data-text *ngSwitchCase="'Text'"
                                      [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                      [visibility]="item.visibility"
                                      [(humanvalue)]="item.humanvalue"
                                      [(value)]="item.value"></app-meta-data-text>

                  <app-meta-data-option *ngSwitchCase="'Option'"
                                        [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                        [visibility]="item.visibility"
                                        [(humanvalue)]="item.humanvalue"
                                        [(value)]="item.value" [default]="item.params"></app-meta-data-option>

                  <app-meta-data-textarea *ngSwitchCase="'Textarea'"
                                          [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                          [visibility]="item.visibility"
                                          [(humanvalue)]="item.humanvalue"
                                          [(value)]="item.value"></app-meta-data-textarea>

                  <app-meta-data-workflow *ngSwitchCase="'Workflow'"
                                          [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                          [visibility]="item.visibility"
                                          [(humanvalue)]="item.humanvalue"
                                          [(value)]="item.value"></app-meta-data-workflow>

                  <app-meta-data-szotar *ngSwitchCase="'Szotar'"
                                        [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                        [visibility]="item.visibility"
                                        [szotarOptionList]="szotarOptionList"
                                        [(humanvalue)]="item.humanvalue"
                                        [(value)]="item.value" [params]="item.params"></app-meta-data-szotar>

                  <app-meta-data-szotar-ac *ngSwitchCase="'SzotarAC'"
                                           [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                           [visibility]="item.visibility"
                                           [szotarOptionList]="szotarOptionList"
                                           [(humanvalue)]="item.humanvalue"
                                           [(value)]="item.value" [params]="item.params"></app-meta-data-szotar-ac>

                  <app-meta-data-szervuser *ngSwitchCase="'SzervUser'"
                                           [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                           [visibility]="item.visibility"
                                           [(humanvalue)]="item.humanvalue"
                                           [(value)]="item.value"></app-meta-data-szervuser>

                  <app-meta-data-szervuser-ac *ngSwitchCase="'SzervUserAC'"
                                              [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                              [visibility]="item.visibility"
                                              [(humanvalue)]="item.humanvalue"
                                              [(value)]="item.value"></app-meta-data-szervuser-ac>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: flex-end; gap:12px; margin-top: 12px;">
            <button mat-stroked-button matStepperPrevious>{{'BACK' | translate}}</button>
            <button mat-stroked-button matStepperNext>{{'NEXT' | translate}}</button>
          </div>
        </ng-template>
      </mat-step>

      <mat-step [stepControl]="fourthFormGroup" label="{{'ATTACHMENTS' | translate}}">
        <ng-template matStepContent>
          <form [formGroup]="fourthFormGroup">
            <div>
              <p class="upload-title">{{'FILE_UPLOAD' | translate}}</p>
              <div class="dropzone"
                   [ngClass]="{ 'dragover': isDragOver }"
                   (drop)="onFileDrop($event)"
                   (dragover)="onDragOver($event)"
                   (dragleave)="onDragLeave($event)">
                <input type="file" id="fileDropRef" multiple
                       style="opacity: 0; position: relative; z-index: 2; width: 100%; height: 100%; top: 0; left: 0;"
                       (change)="uploadingFiles($event)">
                <p class="dropzone-text">{{'CHOOSE_A_FILE' | translate}}</p>
              </div>
              <mat-expansion-panel style="border: none; box-shadow: none; margin-bottom: 22px" togglePosition="before" expanded="true" [disabled]="uploadFileList.length === 0">
                <mat-expansion-panel-header style="padding-left: 2px; padding-right: 0" expandedHeight="48px" collapsedHeight="48px">
                  <mat-panel-title>{{'ATTACHMENTS' | translate}} ({{uploadFileList.length}})</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list style="padding: 0">
                  <div *ngFor="let file of uploadFileList; let i = index">
                    <mat-list-item style="padding-left: 0" class="unset-list-item">
                      <div matListItemTitle class="unset-title">{{file.content.name}}</div>
                      <div matListItemLine>{{formatFileSize(file.content.size)}}</div>
                      <div matListItemLine *ngIf="file.content.size > MAX_FILE_SIZE" style="color: #ED2447">{{'FILE_TOO_LARGE' | translate}}</div>
                      <div matListItemMeta style="display: flex; flex-direction: row; align-items: center; justify-content: center; height: 100%" class="unset-meta-item">
                        <mat-form-field subscriptSizing="dynamic" class="uploaded-file-type">
                          <mat-select placeholder="{{'ATTACHMENT_TYPE' | translate}}" [(value)]="file.attachmentTypeCode" style="line-height: 2">
                            <mat-option [value]="null">
                              {{'NOT_SELECTED' | translate}}
                            </mat-option>
                            <mat-option *ngFor="let fileType of attachmentTypes" [value]="fileType.code">
                              {{fileType.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <button mat-icon-button (click)="removeFile(i)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </mat-list-item>
                    <mat-divider class="divider-position"></mat-divider>
                  </div>
                </mat-list>
              </mat-expansion-panel>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end">
              <button mat-stroked-button matStepperPrevious>{{'BACK' | translate}}</button>
            </div>
          </form>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" style="margin-right: 18px;margin-bottom: 12px;"
          [disabled]="!isAllFormValid()" (click)="createWorkflow()">
    {{'CREATE' | translate}}
  </button>
</mat-dialog-actions>
