<div style="width: 100%; display: flex; justify-content: space-between">
  <h2 mat-dialog-title style="margin-bottom: 0">{{'CREATE_TASK' | translate}}</h2>
  <button mat-icon-button (click)="onNoClick()"><mat-icon>close</mat-icon></button>
</div>

<mat-dialog-content style="min-width: 280px; padding-left: 0; padding-right: 0">

  <div>
    <mat-stepper
      class="example-stepper"
      [orientation]="(stepperOrientation | async)!">

      <mat-step [stepControl]="firstFormGroup" label="{{'INFORMATION' | translate}}">
        <p *ngIf="isInitialLoading">{{'DATA_LOADING' | translate}}</p>
        <form [formGroup]="firstFormGroup" *ngIf="!isInitialLoading">
          <p class="upload-title">{{'INFORMATION' | translate}}</p>
          <div class="wf-data-div">
            <div class="input-box">
              <span class="input-field-label"><b>{{'SUBJECT' | translate}}*</b></span>
              <mat-form-field>
                <input matInput formControlName="subjectCtrl"
                       required (input)="onSubjectChange($event)">
                <mat-error *ngIf="firstFormGroup.get('subjectCtrl')?.hasError('required')">
                  {{'MANDATORY_FIELD' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input-box">
              <span class="input-field-label"><b>{{'PARTNER' | translate}}</b></span>
              <mat-form-field class="field-width">
                <input #inputPartner
                       type="text"
                       placeholder="{{'SEARCHING_FOR_PARTNER' | translate}}"
                       matInput
                       [formControl]="partnerControl"
                       [matAutocomplete]="autoPartner">
                <mat-autocomplete #autoPartner="matAutocomplete" [displayWith]="displayPartnerFn" requireSelection="true"
                                  (optionSelected)="setPartner($event.option.value)">
                  <mat-option *ngIf="partnerNotFound" disabled="true">{{'NO_RESULTS' | translate}}</mat-option>
                  <mat-option *ngFor="let partner of filteredPartnerOptions | async" [value]="partner">
                    {{partner.partnerFullName}}
                  </mat-option>
                </mat-autocomplete>
                <button matSuffix
                        mat-icon-button
                        [disabled]="partnerSearchActive"
                        (click)="onSearchPartnerClicked(inputPartner.value)"
                        class="icon-button">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="input-box" *ngIf="priorityList.length>0 && !isInitialLoading">
              <span class="input-field-label"><b>{{'PRIORITY' | translate}}</b></span>
              <mat-form-field>
                <mat-select (selectionChange)="setPriority($event.value)" placeholder="{{'NOT_SELECTED' | translate}}">
                  <mat-option [value]="null">{{'NOT_SELECTED' | translate}}</mat-option>
                  <mat-option *ngFor="let item of priorityList" [value]="item">{{item.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-box">
              <span class="input-field-label"><b>{{'START_DATE' | translate}}</b></span>
              <mat-form-field class="field-width">
                <input matInput
                       [matDatepicker]="pickerStartDate"
                       placeholder="YYYY. MM. DD."
                       formControlName="startDateCtrl"
                       [min]="minDate"
                       (dateChange)="setStartDate($event.value)">
                <mat-datepicker-toggle matIconSuffix [for]="pickerStartDate" class="icon-button"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #pickerStartDate></mat-datepicker>
                <mat-error *ngIf="firstFormGroup.get('startDateCtrl')?.hasError('min')">
                  {{'START_DATE_TOO_EARLY' | translate}}
                </mat-error>
                <mat-error *ngIf="firstFormGroup.get('startDateCtrl')?.hasError('max')">
                  {{'START_DATE_IS_LATER_THAN_DEADLINE' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input-box">
              <span class="input-field-label"><b>{{'DEADLINE_DATE' | translate}}</b></span>
              <mat-form-field class="field-width">
                <input matInput
                       [matDatepicker]="pickerDeadline"
                       placeholder="YYYY. MM. DD."
                       [formControl]="deadlineControl"
                       [min]="minDeadlineDate"
                       (dateChange)="onDateChange($event.value)">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDeadline" class="icon-button"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #pickerDeadline></mat-datepicker>
                <mat-error *ngIf="deadlineControl.hasError('dateNotBeforeToday')">
                  {{'DEADLINE_TOO_EARLY' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
<!--            <div style="display: flex; flex-direction: row; width: 212px; justify-content: space-between" class="deadline-margin">-->
<!--              <mat-form-field style="width: 90px">-->
<!--                <input-->
<!--                  matInput-->
<!--                  type="number"-->
<!--                  placeholder="HH"-->
<!--                  [formControl]="deadlineHoursControl"-->
<!--                  min="0"-->
<!--                  max="23"-->
<!--                  (change)="validateTimeInput('hours')">-->
<!--              </mat-form-field>-->

<!--              <span style="margin-top: 7px">:</span>-->

<!--              <mat-form-field style="width: 90px">-->
<!--                <input-->
<!--                  matInput-->
<!--                  type="number"-->
<!--                  placeholder="MM"-->
<!--                  [formControl]="deadlineMinutesControl"-->
<!--                  min="0"-->
<!--                  max="59"-->
<!--                  (change)="validateTimeInput('minutes')">-->
<!--              </mat-form-field>-->

<!--              <mat-icon style="margin-top: 7px">access_time</mat-icon>-->
<!--            </div>-->
            <div class="input-box">
              <span class="input-field-label"><b>{{'DEADLINE_TIME' | translate}}</b></span>
              <mat-form-field>
                <input
                  matInput
                  type="time"
                  placeholder="HH:MM"
                  [formControl]="deadlineTimeControl"
                  (change)="onTimeChange($event)"
                  (input)="onInputClear($event)"
                  class="input-height-fix">
                <mat-icon matIconSuffix style="color: rgb(0 0 0 / 38%)">access_time</mat-icon>
              </mat-form-field>
            </div>

            <div class="input-box">
              <span class="input-field-label"><b>{{'NEEDS_APPROVAL_FROM_CREATOR' | translate}}</b></span>
<!--              <mat-checkbox class="checkbox-margin checkbox-label" formControlName="creatorApprovalCtrl">-->
<!--                {{creatorApproved() ? ('YES' | translate) : ('NO' | translate) }}-->
<!--              </mat-checkbox>-->
              <mat-form-field>
                <mat-select (selectionChange)="setCreatorApproval($event.value)" placeholder="{{'NOT_SELECTED' | translate}}">
                  <mat-option [value]="null">{{'NOT_SELECTED' | translate}}</mat-option>
                  <mat-option [value]="true">{{'YES' | translate}}</mat-option>
                  <mat-option [value]="false">{{'NO' | translate}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-box" *ngIf="allowedExecutorTypes.length>0 && !isInitialLoading">
              <span class="input-field-label"><b>{{'EXECUTOR_TYPE' | translate}}*</b></span>
              <mat-form-field>
                <mat-select formControlName="executorTypeCtrl"
                            (selectionChange)="setExecutorType($event.value)"
                            required>
                  <mat-option *ngFor="let item of allowedExecutorTypes" [value]="item">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.get('executorTypeCtrl')?.hasError('required')">
                  {{'MANDATORY_FIELD' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: space-between">
            <p style="color: #000000DE; font-weight: 500; margin-bottom: 0">{{'DESCRIPTION' | translate}}</p>
            <button mat-icon-button (click)="showEditor()" [disabled]="isEditor"><mat-icon>edit</mat-icon></button>
          </div>
          <mat-form-field style="width: 100%" *ngIf="!isEditor">
            <textarea matInput formControlName="descriptionCtrl"></textarea>
          </mat-form-field>
          <quill-editor class="content-editor" formControlName="descriptionCtrl" style="width: 100%; margin-bottom: 22px" *ngIf="isEditor"></quill-editor>
          <div style="display: flex; flex-direction: row; justify-content: flex-end">
            <button mat-stroked-button matStepperNext>{{'NEXT' | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondFormGroup" label="{{'RECIPIENTS' | translate}}">
        <ng-template matStepContent>
          <form [formGroup]="secondFormGroup">
            <div style="display: flex; flex-direction: column">

              <div style="display: flex; flex-flow: row wrap">
                <div class="input-box">
                  <span class="input-field-label"><b>{{'USER' | translate}}</b></span>
                  <mat-form-field class="field-width field-bottom" subscriptSizing="dynamic">
                    <input #inputRecipientUser
                           type="text"
                           placeholder="{{'SEARCHING_FOR_PERSON' | translate}}"
                           matInput
                           (input)="setAddUserButton()"
                           [formControl]="recipientUserControl"
                           [matAutocomplete]="autoRecipientUser">
                    <mat-autocomplete #autoRecipientUser="matAutocomplete"
                                      [displayWith]="displayRecipientUserFn"
                                      requireSelection="true"
                                      (optionSelected)="selectRecipientUser($event.option.value)">
                      <mat-option *ngIf="recipientUserNotFound" disabled="true">{{'NO_RESULTS' | translate}}</mat-option>
                      <mat-option *ngFor="let user of filteredRecipientUserOptions | async" [value]="user">
                        {{user.userName}} ({{user.orgName}})
                      </mat-option>
                    </mat-autocomplete>
                    <button matSuffix
                            mat-icon-button
                            [disabled]="userSearchActive"
                            (click)="onSearchUserClicked(inputRecipientUser.value)"
                            class="icon-button">
                      <mat-icon>search</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="add-button">
                  <button mat-stroked-button [disabled]="!selectedRecipientUser" (click)="addRecipientUser()">
                    {{'ADD' | translate}}
                  </button>
                </div>
              </div>

              <div style="display: flex; flex-flow: row wrap">
                <div class="input-box">
                  <span class="input-field-label"><b>{{'ORGANIZATIONAL_UNIT' | translate}}</b></span>
                  <mat-form-field class="field-width field-bottom" subscriptSizing="dynamic">
                    <input #inputRecipientOrganization
                           type="text"
                           placeholder="{{'SEARCHING_FOR_ORGANIZATION' | translate}}"
                           matInput
                           (input)="setAddOrganizationButton()"
                           [formControl]="recipientOrganizationControl"
                           [matAutocomplete]="autoRecipientOrganization">
                    <mat-autocomplete #autoRecipientOrganization="matAutocomplete"
                                      [displayWith]="displayRecipientOrganizationFn"
                                      requireSelection="true"
                                      (optionSelected)="selectRecipientOrganization($event.option.value)">
                      <mat-option *ngIf="recipientOrganizationNotFound" disabled="true">{{'NO_RESULTS' | translate}}</mat-option>
                      <mat-option *ngFor="let organization of filteredRecipientOrganizationOptions | async" [value]="organization">
                        {{organization.name}}
                      </mat-option>
                    </mat-autocomplete>
                    <button matSuffix
                            mat-icon-button
                            [disabled]="organizationSearchActive"
                            (click)="onSearchOrganizationClicked(inputRecipientOrganization.value)"
                            class="icon-button">
                      <mat-icon>search</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="add-button">
                  <button mat-stroked-button
                          [disabled]="!selectedRecipientOrganization"
                          (click)="addRecipientOrganization()">
                    {{'ADD' | translate}}
                  </button>
                </div>
              </div>
            </div>

<!--            <div>-->
<!--              <span class="upload-title">{{'RECIPIENT_USERS' | translate}}</span>-->
<!--              <div *ngIf="recipientUserList.length===0">-->
<!--                - -->
<!--              </div>-->
<!--              <div *ngFor="let user of recipientUserList; let i = index" class="uploaded-file-div">-->
<!--                <div class="uploaded-file-inner-div">-->
<!--                  <div class="uploaded-file-name">-->
<!--                    {{user.userName}} ({{user.orgName}})-->
<!--                  </div>-->
<!--                  <div style="flex: 0.5; display: flex; flex-direction: row; align-items: end">-->
<!--                    <button mat-icon-button class="remove-button" (click)="removeRecipientUser(i)">-->
<!--                      <mat-icon>delete</mat-icon>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <mat-divider class="divider-position"></mat-divider>-->
<!--              </div>-->
<!--            </div>-->
            <mat-expansion-panel style="border: none; box-shadow: none; margin-bottom: 12px" togglePosition="before" [expanded]="recipientUserList.length > 0" [disabled]="recipientUserList.length === 0">
              <mat-expansion-panel-header style="padding-left: 2px; padding-right: 0" expandedHeight="48px" collapsedHeight="48px">
                <mat-panel-title>{{'RECIPIENT_USERS' | translate}} ({{recipientUserList.length}})</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list style="padding: 0">
                <div *ngFor="let user of recipientUserList; let i = index">
                  <mat-list-item style="padding-left: 0">
                    <div matListItemTitle>{{user.userName}}</div>
                    <div matListItemLine>{{user.orgName}}</div>
                    <div matListItemMeta>
                      <button mat-icon-button (click)="removeRecipientUser(i)" style="margin-top: 8px">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </mat-list-item>
                  <mat-divider class="divider-position"></mat-divider>
                </div>
              </mat-list>
            </mat-expansion-panel>

<!--            <div>-->
<!--              <span class="upload-title">{{'RECIPIENT_ORGANIZATIONS' | translate}}</span>-->
<!--              <div *ngIf="recipientOrganizationList.length===0">-->
<!--                - -->
<!--              </div>-->
<!--              <div *ngFor="let organization of recipientOrganizationList; let i = index" class="uploaded-file-div">-->
<!--                <div class="uploaded-file-inner-div">-->
<!--                  <div class="uploaded-file-name">-->
<!--                    {{organization.name}}-->
<!--                  </div>-->
<!--                  <div style="flex: 0.5; display: flex; flex-direction: row; align-items: end">-->
<!--                    <button mat-icon-button class="remove-button" (click)="removeRecipientOrganization(i)">-->
<!--                      <mat-icon>delete</mat-icon>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <mat-divider class="divider-position"></mat-divider>-->
<!--              </div>-->
<!--            </div>-->
            <mat-expansion-panel style="border: none; box-shadow: none; margin-bottom: 22px" togglePosition="before" [disabled]="recipientOrganizationList.length === 0">
              <mat-expansion-panel-header style="padding-left: 2px; padding-right: 0" expandedHeight="48px" collapsedHeight="48px">
                <mat-panel-title>{{'RECIPIENT_ORGANIZATIONS' | translate}} ({{recipientOrganizationList.length}})</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list style="padding: 0">
                <div *ngFor="let organization of recipientOrganizationList; let i = index">
                  <mat-list-item style="padding-left: 0">
                    <div matListItemTitle>{{organization.name}}</div>
                    <div matListItemMeta>
                      <button mat-icon-button (click)="removeRecipientOrganization(i)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </mat-list-item>
                  <mat-divider class="divider-position"></mat-divider>
                </div>
              </mat-list>
            </mat-expansion-panel>

            <div style="display: flex; flex-direction: row; justify-content: flex-end; gap: 12px">
              <button mat-stroked-button matStepperPrevious>{{'BACK' | translate}}</button>
              <button mat-stroked-button matStepperNext>{{'NEXT' | translate}}</button>
            </div>
          </form>
        </ng-template>
      </mat-step>

      <mat-step [stepControl]="thirdFormGroup" label="{{'ATTACHMENTS' | translate}}">
        <ng-template matStepContent>
          <form [formGroup]="thirdFormGroup">
            <div>
              <p class="upload-title">{{'FILE_UPLOAD' | translate}}</p>
              <div class="dropzone"
                   [ngClass]="{ 'dragover': isDragOver }"
                   (drop)="onFileDrop($event)"
                   (dragover)="onDragOver($event)"
                   (dragleave)="onDragLeave($event)">
                <input type="file" id="fileDropRef" multiple
                       style="opacity: 0; position: relative; z-index: 2; width: 100%; height: 100%; top: 0; left: 0;"
                       (change)="uploadingFiles($event)">
                <p class="dropzone-text">{{'CHOOSE_A_FILE' | translate}}</p>
              </div>
<!--              <div *ngFor="let file of uploadFileList; let i = index" class="uploaded-file-div">-->
<!--                <div class="uploaded-file-inner-div">-->
<!--                  <div class="uploaded-file-name"-->
<!--                       [ngClass]="{'file-too-large': file.content.size > MAX_FILE_SIZE}">-->
<!--                    {{file.content.name}}-->
<!--                    <b>({{formatFileSize(file.content.size)}})</b>-->
<!--                  </div>-->
<!--                  <div style="flex: 0.5; display: flex; flex-direction: row; align-items: end">-->
<!--                    <mat-form-field subscriptSizing="dynamic" class="uploaded-file-type">-->
<!--                      <mat-select placeholder="{{'ATTACHMENT_TYPE' | translate}}" [(value)]="file.attachmentTypeCode" style="line-height: 2">-->
<!--                        <mat-option [value]="null">-->
<!--                          {{'NOT_SELECTED' | translate}}-->
<!--                        </mat-option>-->
<!--                        <mat-option *ngFor="let fileType of attachmentTypes" [value]="fileType.code">-->
<!--                          {{fileType.name}}-->
<!--                        </mat-option>-->
<!--                      </mat-select>-->
<!--                    </mat-form-field>-->
<!--                    <button mat-mini-fab class="remove-button" (click)="removeFile(i)">-->
<!--                      <mat-icon>delete</mat-icon>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <mat-divider class="divider-position"></mat-divider>-->
<!--              </div>-->
              <mat-expansion-panel style="border: none; box-shadow: none; margin-bottom: 22px" togglePosition="before" expanded="true" [disabled]="uploadFileList.length === 0">
                <mat-expansion-panel-header style="padding-left: 2px; padding-right: 0" expandedHeight="48px" collapsedHeight="48px">
                  <mat-panel-title>{{'ATTACHMENTS' | translate}} ({{uploadFileList.length}})</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list style="padding: 0">
                  <div *ngFor="let file of uploadFileList; let i = index">
                    <mat-list-item style="padding-left: 0" class="unset-list-item">
                      <div matListItemTitle class="unset-title">{{file.content.name}}</div>
                      <div matListItemLine>{{formatFileSize(file.content.size)}}</div>
                      <div matListItemLine *ngIf="file.content.size > MAX_FILE_SIZE" style="color: #ED2447">{{'FILE_TOO_LARGE' | translate}}</div>
                      <div matListItemMeta style="display: flex; flex-direction: row; align-items: center; justify-content: center; height: 100%" class="unset-meta-item">
                        <mat-form-field subscriptSizing="dynamic" class="uploaded-file-type">
                          <mat-select placeholder="{{'ATTACHMENT_TYPE' | translate}}" [(value)]="file.attachmentTypeCode" style="line-height: 2">
                            <mat-option [value]="null">
                              {{'NOT_SELECTED' | translate}}
                            </mat-option>
                            <mat-option *ngFor="let fileType of attachmentTypes" [value]="fileType.code">
                              {{fileType.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <button mat-icon-button (click)="removeFile(i)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </mat-list-item>
                    <mat-divider class="divider-position"></mat-divider>
                  </div>
                </mat-list>
              </mat-expansion-panel>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end; gap: 12px">
              <button mat-stroked-button matStepperPrevious>{{'BACK' | translate}}</button>
              <button mat-stroked-button matStepperNext>{{'NEXT' | translate}}</button>
            </div>
          </form>
        </ng-template>
      </mat-step>

      <mat-step [stepControl]="fourthFormGroup" label="{{'RELATED_DOCUMENTS' | translate}}">
        <ng-template matStepContent>
          <form [formGroup]="fourthFormGroup">
            <div style="display: flex; flex-direction: column">

              <div style="display: flex; flex-flow: row wrap">
                <div class="input-box">
                  <span class="input-field-label"><b>{{'DOCUMENT' | translate}}</b></span>
                  <mat-form-field class="field-width field-bottom" subscriptSizing="dynamic">
                    <input #inputDocument
                           type="text"
                           placeholder="{{'SEARCHING_FOR_DOCUMENT' | translate}}"
                           matInput
                           (input)="setAddDocumentButton()"
                           [formControl]="documentControl"
                           [matAutocomplete]="autoDocument">
                    <mat-autocomplete #autoDocument="matAutocomplete" [displayWith]="displayDocumentFn"

                                      (optionSelected)="selectDocument($event.option.value)">
                      <mat-option *ngIf="documentNotFound" disabled="true">{{'NO_RESULTS' | translate}}</mat-option>
                      <mat-option *ngFor="let document of filteredDocumentOptions | async" [value]="document">
                        {{document.identifier}}
                      </mat-option>
                    </mat-autocomplete>
                    <button matSuffix
                            mat-icon-button
                            [disabled]="documentSearchActive || documentControl.invalid || documentControl.value === ''"
                            (click)="onSearchDocumentClicked(inputDocument.value)"
                            class="icon-button">
                      <mat-icon>search</mat-icon>
                    </button>
<!--                    <mat-error *ngIf="documentControl.hasError('patternInvalid')">-->
<!--                      {{'WRONG_FORMAT' | translate}}-->
<!--                    </mat-error>-->
                    <mat-hint align="end">Ex. 1-1/2025</mat-hint>
                  </mat-form-field>
                </div>
                <div class="add-button">
                  <button mat-stroked-button
                          [disabled]="!selectedDocument"
                          (click)="addDocument()">
                    {{'ADD' | translate}}
                  </button>
                </div>
              </div>

              <div style="display: flex; flex-flow: row wrap">
                <div class="input-box">
                  <span class="input-field-label"><b>{{'FOLDER' | translate}}</b></span>
                  <mat-form-field class="field-width field-bottom" subscriptSizing="dynamic">
                    <input #inputFolder
                           type="text"
                           placeholder="{{'SEARCHING_FOR_FOLDER' | translate}}"
                           matInput
                           (input)="setAddFolderButton()"
                           [formControl]="folderControl"
                           [matAutocomplete]="autoFolder">
                    <mat-autocomplete #autoFolder="matAutocomplete" [displayWith]="displayFolderFn"

                                      (optionSelected)="selectFolder($event.option.value)">
                      <mat-option *ngIf="folderNotFound" disabled="true">{{'NO_RESULTS' | translate}}</mat-option>
                      <mat-option *ngFor="let folder of filteredFolderOptions | async" [value]="folder">
                        {{folder.identifier}}
                      </mat-option>
                    </mat-autocomplete>
                    <button matSuffix
                            mat-icon-button
                            [disabled]="folderSearchActive || folderControl.invalid || folderControl.value === ''"
                            (click)="onSearchFolderClicked(inputFolder.value)"
                            class="icon-button">
                      <mat-icon>search</mat-icon>
                    </button>
<!--                    <mat-error *ngIf="folderControl.hasError('patternInvalid')">-->
<!--                      {{'WRONG_FORMAT' | translate}}-->
<!--                    </mat-error>-->
                    <mat-hint align="end">Ex. 1/2025</mat-hint>
                  </mat-form-field>
                </div>
                <div class="add-button">
                  <button mat-stroked-button
                          [disabled]="!selectedFolder"
                          (click)="addFolder()">
                    {{'ADD' | translate}}
                  </button>
                </div>
              </div>

            </div>
            <div>
              <mat-expansion-panel style="border: none; box-shadow: none; margin-bottom: 12px" togglePosition="before" [disabled]="documentList.length === 0">
                <mat-expansion-panel-header style="padding-left: 2px; padding-right: 0" expandedHeight="48px" collapsedHeight="48px">
                  <mat-panel-title>{{'RELATED_DOCUMENTS' | translate}} ({{documentList.length}})</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list style="padding: 0">
                  <div *ngFor="let document of documentList; let i = index">
                    <mat-list-item style="padding-left: 0">
                      <div matListItemTitle>{{document.identifier}}</div>
                      <div matListItemMeta>
                        <button mat-icon-button (click)="removeDocument(i)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </mat-list-item>
                    <mat-divider class="divider-position"></mat-divider>
                  </div>
                </mat-list>
              </mat-expansion-panel>
            </div>
            <div>
              <mat-expansion-panel style="border: none; box-shadow: none; margin-bottom: 22px" togglePosition="before" [disabled]="folderList.length === 0">
                <mat-expansion-panel-header style="padding-left: 2px; padding-right: 0" expandedHeight="48px" collapsedHeight="48px">
                  <mat-panel-title>{{'RELATED_FOLDERS' | translate}} ({{folderList.length}})</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list style="padding: 0">
                  <div *ngFor="let folder of folderList; let i = index">
                    <mat-list-item style="padding-left: 0">
                      <div matListItemTitle>{{folder.identifier}}</div>
                      <div matListItemMeta>
                        <button mat-icon-button (click)="removeFolder(i)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </mat-list-item>
                    <mat-divider class="divider-position"></mat-divider>
                  </div>
                </mat-list>
              </mat-expansion-panel>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end">
              <button mat-stroked-button matStepperPrevious>{{'BACK' | translate}}</button>
            </div>
          </form>
        </ng-template>
      </mat-step>

    </mat-stepper>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" style="margin-right: 18px;margin-bottom: 12px;"
          [disabled]="!isAllFormValid() && !isUploading" (click)="createTask()">
    {{'CREATE' | translate}}
  </button>
</mat-dialog-actions>
